import { useEffect } from 'react'

import _ from 'lodash'

const useWatchClickOutside = (refs, callback) => {
  const targetClick = event => {
    let outsideAll = true
    if (!_.isArray(refs)) refs = [refs]

    refs.forEach(ref => {
      if (ref.current && !(ref.current && !ref.current?.contains(event.target)))
        outsideAll = false
    })

    if (outsideAll) callback()
  }

  useEffect(() => {
    window.document.addEventListener('mousedown', targetClick)

    return () => {
      window.document.removeEventListener('mousedown', targetClick)
    }
  }, [])
}

export default useWatchClickOutside

import { useClientSubscriptionContext } from 'src/domain/ClientSubscription/context'

import { Information } from '@carbon/icons-react'

import Tooltip from '@/components/Tooltip'

import styles from './Card/styles.module.scss'

const InfoTooltip = ({
  white,
  rapporteur,
  county,
  processNumber,
  subject,
  referendary,
  rgStatus,
  judgedAt,
  themeSituation,
  kind,
  className
}) => {
  const { hasActivePlan } = useClientSubscriptionContext()

  if (kind == 'REPERCUSSAO_GERAL') {
    return (
      <>
        {hasActivePlan ? (
          <Tooltip
            interactive
            placement="right"
            content={
              <>
                {subject && <>Assunto: {subject}</>}
                {referendary && <>Relator: {referendary}</>}
                {rgStatus && (
                  <>
                    <br />
                    Status: {rgStatus}
                  </>
                )}
                {judgedAt && (
                  <>
                    <br />
                    Data de julgamento: {judgedAt}
                  </>
                )}
                {themeSituation && (
                  <>
                    <br />
                    Situação tema: {themeSituation}
                  </>
                )}
              </>
            }
          >
            <Information
              className={`mb-1  ${white ? 'text-white' : 'text-gray-700'} ${
                styles.InformationTooltip
              } ${{
                className
              }}`}
            />
          </Tooltip>
        ) : (
          <div className="d-flex align-items-center">
            <Information />
          </div>
        )}
      </>
    )
  }

  return (
    <>
      {hasActivePlan ? (
        <Tooltip
          interactive
          placement="right"
          content={
            <>
              {rapporteur && (
                <>
                  Relator: {rapporteur.name}
                  <br />
                </>
              )}
              {county && (
                <>
                  Comarca: {county.name}
                  <br />
                </>
              )}
              {subject && (
                <>
                  <br />
                  Assunto: {subject}
                </>
              )}
              {processNumber && (
                <>
                  <br />
                  Processo: {processNumber}
                </>
              )}
            </>
          }
        >
          <Information
            className={`mb-1  ${white ? 'text-white' : 'text-gray-700'} ${
              styles.InformationTooltip
            } ${{
              className
            }}`}
          />
        </Tooltip>
      ) : (
        <div className="d-flex align-items-center">
          <Information className="text-gray-400" />
        </div>
      )}
    </>
  )
}

export default InfoTooltip

import { useAppContext } from '@/domain/App/context'

const VisitedInfo = ({ id, white, className = '' }) => {
  const { documentWasVisited } = useAppContext()
  const [visited, visitedDate, visitedDateString] = documentWasVisited(id)

  if (!visited) return <></>
  return (
    <span
      className={`fs-6 d-inline-block ${
        white ? 'text-white' : 'text-gray-700'
      } ${className}`}
    >
      Lido {visitedDateString.toLowerCase()}
    </span>
  )
}

export default VisitedInfo

import { RecentlyViewed, BookmarkFilled } from '@carbon/icons-react'
const documents = [
  {
    id: 1,
    search: 'Loucuraaaaaa aaaa aaaaaaa aaaaaaaaaaaaaaa',
    href: '/loucura',
    flag: '01-brasil-circle',
    court: 'STF',
    publishedAt: '10/12/2022',
    icon: <RecentlyViewed />
  },
  {
    id: 232,
    search: 'Loucura',
    href: '/teste2',
    flag: '01-brasil-circle',
    court: 'STF',
    publishedAt: '07/10/1996',
    icon: <RecentlyViewed />
  }
]
export default documents

export const savedDocuments = [
  {
    id: 1,
    search: 'Eu to salvo',
    href: '/loucura',
    flag: '01-brasil-circle',
    court: 'STF',
    publishedAt: '10/12/2022',
    icon: <BookmarkFilled />
  },
  {
    id: 232,
    search: 'Estou completamente salvo',
    href: '/teste2',
    flag: '01-brasil-circle',
    court: 'STF',
    publishedAt: '07/10/1996',
    icon: <BookmarkFilled />
  }
]

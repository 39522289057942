import Link from 'next/link'

export const Footer = () => {
  const social = [
    {
      path: 'https://instagram.com/maestrialaw',
      icon: 'instagram'
    },
    {
      path: 'https://www.facebook.com/profile.php?id=61552314261013',
      icon: 'facebook'
    },
    {
      path: 'https://www.linkedin.com/company/maestrialaw/',
      icon: 'linkedin'
    }
  ]

  return (
    <div className="w-100 bg-gray-950">
      <div className="container">
        <div className="div1  w-100 d-flex gap-12">
          {/* <div className="resources d-flex flex-column gap-5">
            <span className="text-gray-300 fs-2 fw-normal mt-12 mb-5">
              Recursos
            </span>
            {links.map(({ name, path }) => {
              return (
                <Link
                  key={path}
                  className="text-white fs-5 fw-normal"
                  href={path}
                >
                  {name}
                </Link>
              )
            })}
          </div> */}
          <div className="div-contato row">
            <div className="col-lg-8 mt-12">
              <div className="d-flex flex-column gap-5">
                <span className="text-gray-200 fs-3 me-10 mb-5 d-block lh-base">
                  Descubra um novo nível de excelência em pesquisas jurídicas
                  com a Maestria, a ferramenta que utiliza busca semântica para
                  impulsionar sua eficiência e precisão!
                </span>
                <span className="text-gray-200 fs-3">
                  Acompanhe nossas redes sociais:
                </span>
                <div className="d-flex gap-5 ">
                  {social.map(({ icon, path }) => (
                    <Link
                      key={icon}
                      href={path}
                      target="_blank"
                      className="d-flex align-items-center rounded-circle bg-gray-900 px-2 py-2"
                    >
                      <i
                        className={`bx bxl-${icon} cursor-pointer text-white text-hover-primary fs-2`}
                      />
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-12 mb-auto">
              <div className="d-flex flex-column ms-lg-15">
                <span className="text-white fs-2 fw-bold">Contato</span>
                <Link
                  className="text-hover-primary fs-3 text-gray-200 mt-5"
                  href="mailto:contato@maestrialaw.com.br"
                  target="_blank"
                >
                  <span className="d-flex align-items-center text-wrap">
                    <i className="fa-regular fa-envelope me-3 fs-2 text-primary"></i>
                    contato@maestrialaw.com.br
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <hr className="text-gray-400" />
        <div className="row">
          <div className="col-md-7 text-white mb-4 mb-md-14 mt-3">
            © Copyright MAESTRIA 2023 - Todos os direitos reservados.
          </div>
          <div className="col-md-5 text-md-end mt-md-3 mb-4">
            {/* <span className="fs-6 fw-normal">|</span>
            <span className="fs-6 fw-normal">CNPJ 53.218.393/0001-70</span> */}
            {/* <span className="fs-6 fw-normal">|</span> */}
            <Link
              href="http://site.maestrialaw.com.br"
              target="_blank"
              className="fs-3 fw-normal text-white me-6"
              rel="noreferrer"
            >
              Sobre
            </Link>
            <Link
              href="/Termos de uso Maestria.pdf"
              target="_blank"
              className="fs-3 fw-normal text-white"
              rel="noreferrer"
            >
              Termos e condições
            </Link>
            {/* <span className="fs-6 fw-normal">|</span> */}
            {/* <Link
              href="/politica-de-privacidade"
              className="fs-6 fw-normal text-white"
            >
              Política de privacidade
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  )
}

import { forwardRef } from 'react'

import { RecentlyViewed, Close } from '@carbon/icons-react'
import classNames from 'classnames'
import Link from 'next/link'

import styles from './styles.module.scss'

import { useAppContext } from '@/domain/App/context'
import RecentDocument from '@/domain/Documents/ShortcutList/Recent.jsx'
import documents from '@/domain/Documents/ShortcutList/documents.js'

const DropdownRef = (
  {
    isOpen = false,
    recentSearches,
    className,
    blue,
    handleDelete,
    handleSearch,
    saved,
    home
  },
  ref
) => {
  const { visitedDocuments } = useAppContext()
  return (
    <>
      {isOpen ? (
        <div ref={ref} className={`${className} ${styles.SearchDropdown}`}>
          {recentSearches &&
            recentSearches.map(search => {
              return (
                <DropdownSearchItem
                  blue={blue}
                  key={search}
                  search={search}
                  handleDelete={handleDelete}
                  handleSearch={handleSearch}
                />
              )
            })}
          {visitedDocuments &&
            Object.keys(visitedDocuments).length > 0 &&
            !home && <hr className="text-gray-200 m-0" />}
          {!home && (
            <RecentDocument blue={blue} documents={documents} saved={saved} />
          )}
        </div>
      ) : (
        ''
      )}
    </>
  )
}
const Dropdown = forwardRef(DropdownRef)

const DropdownSearchItem = ({ search, blue, handleSearch, handleDelete }) => {
  const classSearchItem = classNames(
    `d-flex align-items-center justify-content-center gap-4 fs-5 cursor-pointer ${styles.SearchDropdownItem}`,
    { 'bg-primary-800 text-white bg-hover-alpha-50': blue }
  )

  const classSearchItemLink = classNames(
    'w-100 py-3 ms-2 d-flex align-items-center gap-4',
    {
      'text-white ': blue,
      'text-gray-900': !blue
    }
  )

  return (
    <>
      <div key={search} className={classSearchItem}>
        <span
          onClick={() => handleSearch(search)}
          className={classSearchItemLink}
        >
          <RecentlyViewed />
          <span className="lh-1">{search}</span>
        </span>
        <Close className="me-2" onClick={() => handleDelete(search)} />
      </div>
    </>
  )
}

export default Dropdown

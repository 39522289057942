import { Fragment, useEffect, useState } from 'react'

import classNames from 'classnames'
import _ from 'lodash'
import Image from 'next/image'
import Link from 'next/link'
import qs from 'qs'

import { RecentlyViewed } from '@carbon/icons-react'

import styles from '../../Search/Input/styles.module.scss'
import cardStyles from '../Card/styles.module.scss'
import InfoTooltip from '../InfoTooltip'
import getCourtFlag from '../utils/getCourtFlag'
import DocumentVisitedInfo from '../VisitedInfo'

import { useAppContext } from '@/domain/App/context'
import { selfApi } from '@/services/api'
import { formatDateString } from '@/services/format/date'

import DocumentListSkeleton from './Skeleton'

const RecentDocument = ({ blue, saved, home, white }) => {
  const { visitedDocuments, documentWasVisited } = useAppContext()
  const [documents, setDocuments] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const classDocumentItem = classNames(
    `d-flex align-items-center  gap-4 fs-5 cursor-pointer `,
    {
      'bg-primary-700  bg-hover-alpha-10 text-white': blue,
      'text-gray-900': !blue
    }
  )
  const classDocumentColor = classNames({
    'text-white': blue,
    'text-gray-900': !blue
  })

  const handleRecentDocuments = async () => {
    const documentsIds = visitedDocuments.map(doc => doc.id)
    if (documentsIds.length == 0) return
    setIsLoading(true)

    try {
      const resDocuments = await selfApi.get(
        `/search/findMany?${qs.stringify(
          { ids: documentsIds.map(String) },
          {
            arrayFormat: 'brackets'
          }
        )}`
      )
      setDocuments(resDocuments.data.records || [])
    } catch (error) {}
    setIsLoading(false)
  }

  useEffect(() => {
    handleRecentDocuments()
  }, [])

  return (
    <>
      {isLoading ? (
        <>
          {_.range(0, visitedDocuments.length).map(i => (
            <DocumentListSkeleton key={i} />
          ))}
        </>
      ) : (
        <>
          {documents.map(doc => {
            const [visited, visitedDate, visitedDateString] =
              documentWasVisited(doc.id)

            return (
              <div
                key={doc.id}
                className={`${classDocumentItem} ${styles.SearchDocumentItem} ${cardStyles.ShowInformationTooltipOnHover}`}
              >
                <Link
                  href={`/documentos/${doc.id}`}
                  className={`w-100 text-gray-900 py-3 ms-2 d-flex align-items-center gap-4 `}
                >
                  <span className={classDocumentColor}>
                    <RecentlyViewed />
                  </span>
                  <div className="d-flex flex-column gap-1 flex-fill text-white">
                    {visited ? (
                      <>
                        <span
                          className={`lh-1 fw-bold text-nowrap text-truncate ${
                            !blue && 'text-gray-900'
                          }`}
                        >
                          {doc.name}
                        </span>
                        {white ? (
                          <DocumentVisitedInfo white id={doc.id} />
                        ) : (
                          <DocumentVisitedInfo id={doc.id} />
                        )}
                      </>
                    ) : (
                      <span className="lh-1 fw-bold text-nowrap text-truncate h-40px d-flex align-items-center">
                        {doc.name}
                      </span>
                    )}
                  </div>

                  <div className="d-flex flex-column flex-sm-row gap-2 align-items-sm-center text-white">
                    <div className="mt-1 me-1 d-none d-lg-block">
                      <InfoTooltip
                        white
                        className={`${styles.SearchDropdownDocumentIcon} ${classDocumentColor}`}
                        rapporteur={doc.rapporteur}
                        county={doc.county}
                        processNumber={doc.processNumber}
                      />
                    </div>
                    <div className="d-flex gap-2">
                      <Image
                        src={getCourtFlag(doc.court.slug)}
                        alt={doc.name || ''}
                        width={16}
                        height={16}
                        className="me-2"
                      />
                      {doc.court.acronym && (
                        <span className={classDocumentColor}>
                          {doc.court.acronym}
                        </span>
                      )}
                    </div>

                    {doc.publishedAt && (
                      <span
                        className={`${classDocumentColor} d-none d-sm-block`}
                      >
                        |
                      </span>
                    )}

                    <span className={`${classDocumentColor} me-2`}>
                      {formatDateString(doc.publishedAt)}
                    </span>
                  </div>
                </Link>
              </div>
            )
          })}
        </>
      )}
    </>
  )
}
export default RecentDocument

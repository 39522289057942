import _ from 'lodash'

const courtFlags = {
  tjac: '02-acre-circle.svg',
  tjal: '03-alagoas-circle.svg',
  tjam: '05-amazonas-circle.svg',
  tjap: '04-amapa-circle.svg',
  tjba: '06-bahia-circle.svg',
  tjce: '07-ceara-circle.svg',
  tjdf: '08-distrito-federal-circle.svg',
  tjdft: '08-distrito-federal-circle.svg',
  tjes: '09-espirito-santo-circle-v2.svg',
  tjgo: '10-goias-circle.svg',
  tjma: '11-maranhao-circle.svg',
  tjmg: '14-minas-gerais-circle.svg',
  tjms: '13-mato-grosso-do-sul-circle.svg',
  tjmt: '11-maranhao-circle.svg',
  tjpa: '15-para-circle.svg',
  tjpb: '16-paraiba-circle.svg',
  tjpe: '18-pernambuco-circle.svg',
  tjpi: '19-piaui-circle.svg',
  tjpr: '17-parana-circle.svg',
  tjrj: '20-rio-de-janeiro-circle.svg',
  tjrn: '21-rio-grande-do-norte-circle.svg',
  tjro: '23-rondonia-circle.svg',
  tjrr: '24-roraima-circle.svg',
  tjrs: '22-rio-grande-do-sul-circle.svg',
  tjsc: '25-santa-catarina-circle.svg',
  tjse: '27-sergipe-circle.svg',
  tjsp: '26-sao-paulo-circle.svg',
  tjto: '28-tocantins-circle.svg'
}

const getCourtFlag = slug => {
  const courtFlag = _.get(
    courtFlags,
    slug.toLowerCase(),
    '01-brasil-circle.svg'
  )

  return `/court-flags/${courtFlag}`
}

export default getCourtFlag

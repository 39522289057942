import { useState, useCallback } from 'react'
import { useEffect, useRef, createRef } from 'react'

import classNames from 'classnames'
import { cpf } from 'cpf-cnpj-validator'
import _ from 'lodash'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'

import { Search } from '@carbon/icons-react'

import { useAppContext } from '@/domain/App/context'
import useDetectEsc from '@/hooks/useDetectEsc'
import useWatchClickOutside from '@/hooks/useWatchClickOutside'

import Dropdown from './Dropdown'
import styles from './styles.module.scss'

const SearchInput = ({ home, blue }) => {
  const inputRef = useRef()
  const dropdownRef = useRef()
  const router = useRouter()
  const { q } = router.query
  const { recentSearches, setRecentSearches, handleDeleteRecentSearch } =
    useAppContext()
  const [isOpen, setIsOpen] = useState(false)
  const [search, setSearch] = useState('')
  const classSearchInput = classNames(
    `ps-0 py-3 pe-10 w-100 fs-2 bg-transparent border-0 form-control text-danger ${styles.SearchInput}`
  )

  const handleInputClick = () => setIsOpen(true)
  const handleCPFChecker = stringToCheck => {
    let notifyCounter = false

    const filteredString = stringToCheck
      .split(' ')
      .filter(string => {
        if (cpf.isValid(string) == true) notifyCounter = true

        return cpf.isValid(string) == false
      })
      .join(' ')

    if (notifyCounter)
      toast.error('O CPF encontrado na pesquisa será ignorado.')

    return filteredString
  }
  const handleSearch = search => {
    if (!search) return

    const filteredString = handleCPFChecker(search)

    router.push(`/busca?q=${filteredString}`)

    setIsOpen(false)
  }

  useWatchClickOutside([inputRef, dropdownRef], () => setIsOpen(false))
  useDetectEsc(() => setIsOpen(false))
  useEffect(() => {
    if (q) {
      setSearch(q)
      setRecentSearches(prevState => _.uniq([q, ...prevState]).splice(0, 5))
      setIsOpen(false)
    }
  }, [q])
  useEffect(() => home && inputRef.current?.focus(), [])

  return (
    <>
      <form
        onSubmit={e => {
          e.preventDefault()
          handleSearch(search)
        }}
        className={`${home && 'mb-5'}`}
      >
        <div className="position-relative w-100">
          <input
            ref={inputRef}
            type="text"
            name="q"
            onClick={handleInputClick}
            className={classSearchInput}
            placeholder="Buscar jurisprudência"
            value={search}
            onChange={e => {
              setIsOpen(true)
              setSearch(e.target.value)
            }}
            maxLength="2048"
            aria-autocomplete="both"
            aria-haspopup="false"
            autoCapitalize="off"
            autoComplete="off"
            autoCorrect="off"
            autoFocus={home}
            spellCheck="false"
            title="Buscar jurisprudência"
            aria-label="Buscar jurisprudência"
          />
          <Dropdown
            recentSearches={recentSearches}
            handleDelete={handleDeleteRecentSearch}
            blue={blue}
            home={home}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            ref={dropdownRef}
            handleSearch={handleSearch}
          />
          {home && <hr className="text-white m-0 opacity-100" />}
          <button
            type="submit"
            className={`btn btn-transparent position-absolute top-0 end-0 h-100 d-flex align-items-center ps-4 pe-0 py-0 ${styles.SearchInputButton}`}
          >
            <Search aria-label="Buscar" className="w-20px h-20px" />
          </button>
        </div>
      </form>
    </>
  )
}

export default SearchInput

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'

const DocumentListSkeleton = ({ className = '' }) => {
  return (
    <SkeletonTheme baseColor="#d4d4d4" highlightColor="white">
      <div className={`d-flex w-600px h-60px ${className}`}>
        <div>
          <Skeleton className="h-15px w-15px ms-2 mt-6 " />
        </div>
        <div className="d-flex ms-2 me-18 flex-column justify-content-center">
          <Skeleton className="h-15px w-300px ms-2 " />
          <Skeleton className="h-10px w-60px ms-2 " />
        </div>
        <div className="d-flex align-items-center">
          <Skeleton circle={true} className="h-15px w-15px ms-n1 " />

          <Skeleton className="h-15px w-30px ms-4 " />
          <span className="ms-2 text-white fs-5">|</span>

          <Skeleton className="h-15px w-100px ms-2 " />
        </div>
      </div>
    </SkeletonTheme>
  )
}

export default DocumentListSkeleton
